import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { userActions, getLogInStatus, decryptDataObject } from "../../store";
import { OvalLoading } from "../../components";

export const LoginScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginStatus = useSelector(getLogInStatus);
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionToken");
  useEffect(() => {
    document.title = "Documenter | Sandbox";
    if (!sessionId) {
      navigate("/");
    }
    dispatch(userActions.validateToken({ token: sessionId }));
  }, []);
  useEffect(() => {
    if (loginStatus) {
      let redirect,
        path = "",
        urlObj;
      urlObj = new URLSearchParams(window.location.search);
      redirect = urlObj.get("redirect");
      dispatch(userActions.logInSuccessClear());
      if (redirect) {
        let dataObj = decryptDataObject(redirect);
        if (Object.values(dataObj).length > 0) {
          if (dataObj.path) {
            path += dataObj.path;
          }
          if (dataObj.search) {
            path += dataObj.search;
          }
        }
      } else {
        path = "/";
      }
      console.log("path", path);
      navigate(path);
    }
  }, [loginStatus]);
  return (
    <div className="col w-100 h-100">
      <OvalLoading message="Logging...." messageClassName="f6" />
    </div>
  );
};
